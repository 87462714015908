import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Onus',
        projectDesc: 'Warehouse Management System',
        tags: ['Php', 'MySQL', 'Bootstrap'],
        code: '',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: one
    },
    {
        id: 2,
        projectName: 'CAPIM',
        projectDesc: '',
        tags: ['PHP', 'Laravel', 'MySQL', 'Google Cloud'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: two
    },
    {
        id: 3,
        projectName: 'Wheel In',
        projectDesc: '.',
        tags: ['PHP', 'Laravel', 'MySQL', 'Google Cloud'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: three
    },
    {
        id: 4,
        projectName: 'Molthmex',
        projectDesc: 'This project involves the development of an Android application for viewing and managing patient data.',
        tags: ['PHP', 'CodeIgnite'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: four
    },
    {
        id: 5,
        projectName: 'Alianza Bajio',
        projectDesc: '',
        tags: ['PHP', 'Laravel', 'MySQL', 'Google Cloud'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: five
    },
    {
        id: 6,
        projectName: 'Tutorel',
        projectDesc: '',
        tags: ['Vue', 'MongoDB', 'Adonis'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: six
    },
    {
        id: 7,
        projectName: 'Opticas 2020',
        projectDesc: '',
        tags: ['Vue', 'Vuex', 'MongoDB', 'Adonis'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: seven
    },
    {
        id: 8,
        projectName: 'Industria Nacional de Autopartes',
        projectDesc: '',
        tags: ['Wordpress'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: eight
    },
    {
        id: 9,
        projectName: 'Snowball Metaverse',
        projectDesc: '',
        tags: ['Hubs'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: eight
    },
    {
        id: 10,
        projectName: 'Payment System',
        projectDesc: '',
        tags: ['PHP', 'Laravel', 'MySQL'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: eight
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/