export const socialsData = {
    github: 'https://github.com/ramsessal',
    facebook: 'https://www.facebook.com/ramsessalr/',
    linkedIn: 'https://www.linkedin.com/in/ramsessal',
    instagram: 'https://www.instagram.com/ramses_sal',
    tiktok: 'https://www.tiktok.com/@ramses_sal',
    codepen: 'https://codepen.io/',
    twitter: 'https://twitter.com/ramsessalr',
    reddit: 'https://www.reddit.com/user/',
    blogger: 'https://www.blogger.com/',
    medium: 'https://medium.com/@',
    stackOverflow: 'https://stackoverflow.com/users/',
    gitlab: 'https://gitlab.com/',
    youtube: 'https://www.youtube.com/@ramsessalinas3393'
}