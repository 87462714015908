export const organizationData = [
    {
        id: 1,
        company: 'Talent-Land',
        jobtitle: 'Mentor',
        startYear: '2022',
        endYear: ''
    },
    {
        id: 2,
        company: 'Galactic Mentor',
        jobtitle: 'NASA International Space Apps Challenge',
        startYear: '2020',
        endYear: ''
    },
    {
        id: 3,
        company: 'INC B-Challenge, INC MTY',
        jobtitle: 'Mentor',
        startYear: '2019',
        endYear: ''
    },
    {
        id: 4,
        company: 'School of Artificial Intelligence Cd. Juárez',
        jobtitle: 'Dean',
        startYear: '2019',
        endYear: ''
    },
    {
        id: 5,
        company: 'Space Science Student Society',
        jobtitle: 'Technology Coordinator',
        startYear: '2017',
        endYear: '2019'
    },
]