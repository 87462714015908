export const experienceData = [
    {
        id: 1,
        company: 'Cámara Nacional de la Industria Electrónica de Telecomunicaciones y Tecnologías de la Información Sede Norte',
        jobtitle: 'President',
        startYear: '2022',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Primal',
        jobtitle: 'Chief Technology Officer',
        startYear: '2020',
        endYear: 'Present'
    },
    {
        id: 3,
        company: 'CAPIM',
        jobtitle: 'Technology Consoultant',
        startYear: '2020',
        endYear: 'Present'
    },
    {
        id: 4,
        company: 'Oriens',
        jobtitle: 'Product Owner',
        startYear: '2018',
        endYear: '2020'
    },
    {
        id: 5,
        company: 'Oriens',
        jobtitle: 'Scrum Master',
        startYear: '2017',
        endYear: '2018'
    },
    {
        id: 6,
        company: 'Oriens',
        jobtitle: 'Fullstack Developer',
        startYear: '2016',
        endYear: '2017'
    },
]