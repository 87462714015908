import resume from '../assets/pdf/resume.pdf'
import photo from '../assets/jpg/perfil.jpg'

export const headerData = {
    name: 'Ramses Salinas',
    title: "Chief Technology Officer",
    desciption:"\"Technology is nothing. What's important is what we do with it\" It's from W. Edwards Deming, and it's a powerful reminder that it's not the technology itself that matters, but how we use it. Sure, advancements in technology are exciting and can be impressive, but they're just tools. The real value is in how we use them to improve our lives and society. So let's not get caught up in the latest gadgets or software, but instead focus on how we can use them to drive progress and innovation." ,
    image: photo,
    resumePdf: resume
}
