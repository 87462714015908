export const educationData = [
    {
        id: 1,
        institution: 'Centro de Bachillerato Tecnológico Industrial y de Servicios.',
        course: 'Programming',
        startYear: '2014',
        endYear: '2017'
    },
    {
        id: 2,
        institution: 'Korea Foundation and Autonomous University of Nuevo Leon',
        course: 'Diplomat Korea in the Regional Scenario of East',
        startYear: '2018',
        endYear: '2019'
    },
    {
        id: 3,
        institution: 'Universidad Autonoma de Ciudad Juárez',
        course: 'Bachelor of Business Administration',
        startYear: '2017',
        endYear: 'Present'
    },
]